import React, { Component } from 'react';
import * as PANOLENS from 'panolens';

class Dummy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPreloader: true,
    };
  }

  componentDidMount() {
    const panorama = new PANOLENS.ImagePanorama('https://ik.imagekit.io/kassenzio/ms/tr:f-auto/Pool4k.jpg');
    const viewer = new PANOLENS.Viewer({
      container: this.imageContainer,
      autoRotate: false,
      autoHideInfospot: false,
      cameraFov: 80,
      controlBar: true,
      control: {
        rotateSpeed: 0.1,
      },
    });

    viewer.container.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
    viewer.add(panorama);

    const hotspots = [
      {
        position: { x: -900, y: -50, z: 100 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/Bedroom-HS.png',
        link: '/bedroom',
      },
    ];

    // Touchscreen fix !important **** IMPORTANT ****

    hotspots.forEach((data, index) => {
      const customHotspot = new PANOLENS.Infospot(100, data.image);
      customHotspot.position.set(data.position.x, data.position.y, data.position.z);
      panorama.add(customHotspot);

      // Handle hotspot click
      customHotspot.addEventListener('click', () => {
        window.location.href = data.link;
      });
    });

    const control = viewer.getControl();
    control.addEventListener('change', () => {
      if (viewer.camera.rotation.x < -Math.PI / 8) {
        viewer.camera.rotation.x = -Math.PI / 8;
      } else if (viewer.camera.rotation.x > Math.PI / 8) {
        viewer.camera.rotation.x = Math.PI / 8;
      }
    });

    panorama.addEventListener('load', () => {
      this.setState({ showPreloader: false });
    });
  }

  render() {
    const { showPreloader } = this.state;

    return (
      <div>
        {/* Preloader */}
        {showPreloader && (
          <div className="preloader">
            <div className="preloader-inner">
              <video
                autoPlay
                controls={false}
                muted
                playsInline
                className="intro-videop"
                preload="auto"
                loop
              >
                <source src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/loader.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        )}

        <div className="threed-cover">
          <div
            className="image-container"
            ref={(container) => {
              this.imageContainer = container;
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Dummy;
