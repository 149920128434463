import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home/home';

import './styles/main.css'
import './styles/responsive.css'
import Livingroom from './rooms/livingroom';
import Pool from './rooms/pool';
import Bathroom from './rooms/bathroom';
import Bedroom from './rooms/bedroom';
import Dummy from './rooms/dummyroom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>

    <header>
      <a href='/'><img className='logo' src='http://mahasaghar.com/shop/wp-content/uploads/2024/03/LAT-LOGO-MG.png' alt='mahasaghar logo'/></a>
      <a href='/shop' className='shop'>Shop</a>
    </header>
    
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/livingroom' element={<Livingroom/>} />
      <Route exact path='/bedroom' element={<Bedroom/>} />
      <Route exact path='/bathroom' element={<Bathroom/>} />
      <Route exact path='/pool' element={<Pool/>} />
      <Route exact path='/dummy' element={<Dummy/>} />
    </Routes>
  </BrowserRouter>
);