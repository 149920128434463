import React, { Component } from 'react';
import * as PANOLENS from 'panolens';


class Pool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreloader: true,
      preloaderFinished: false,
      fadeIn: false
    };
  }

  componentDidMount() {

    // Create a panorama
    const panorama = new PANOLENS.ImagePanorama('https://ik.imagekit.io/kassenzio/ms/tr:f-auto/Pool4k.jpg');
    const viewer = new PANOLENS.Viewer({
      container: this.imageContainer,
      autoRotate: false,
      controlBar: false,
      autoHideInfospot: false,
      cameraFov: 80,
      control: {
        rotateSpeed: 0.1,
      },
    });

    viewer.container.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
    panorama.rotation.y = Math.PI / -2;

    viewer.add(panorama);

    const control = viewer.getControl();
    control.addEventListener('change', () => {
      if (viewer.camera.rotation.x < -Math.PI / 8) {
        viewer.camera.rotation.x = -Math.PI / 8;
      } else if (viewer.camera.rotation.x > Math.PI / 8) {
        viewer.camera.rotation.x = Math.PI / 8;
      }
    });

    // Create Hotspots

    const hotspots = [
      {
        position: { x: -900, y: -50, z: 100 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/bedroom.png', // Bedroom
        link: '/bedroom', // Bedroom URL
      },
      {
        position: { x: 700, y: -200, z: 550 },
        image: 'https://ik.imagekit.io/kassenzio/ms/festive.png', // Festive
        link: 'https://mahasaghar.com/shop/product-category/festive/', // Festive URL
      },
      {
        position: { x: 500, y: -200, z: 900 },
        image: 'https://ik.imagekit.io/kassenzio/ms/bestsellers.png', // Bestsellers
        link: 'https://mahasaghar.com/shop/product-category/bestsellers/', // Bestsellers URL
      },
      {
        position: { x: 700, y: -50, z: 50 },
        image: 'https://ik.imagekit.io/kassenzio/ms/furniture.png', // Furniture
        link: 'https://mahasaghar.com/shop/product-category/furniture/', // Furniture URL
      },
      {
        position: { x: 750, y: -150, z: -180 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/mindfulness.png', // Mindfulness
        link: 'https://mahasaghar.com/shop/product-category/mindfulness/', // Mindfulness URL
      },
      {
        position: { x: 550, y: -200, z: -650 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/beverages.png', // Beverages
        link: 'https://mahasaghar.com/shop/product-category/beverages/', // Beverages URL
      },
      {
        position: { x: 500, y: -300, z: -150 },
        image: 'https://ik.imagekit.io/kassenzio/ms/FITNESS.png', // Fitness
        link: 'https://mahasaghar.com/shop/product-category/fitness/', // Fitness URL
      },
    ];

    hotspots.forEach((data, index) => {
      const customHotspot = new PANOLENS.Infospot(100, data.image);
      customHotspot.position.set(data.position.x, data.position.y, data.position.z);
      panorama.add(customHotspot);
    
      // Handle hotspot click
      customHotspot.addEventListener('click', () => {
        this.setState({ fadeIn: false }); // Trigger the fade-in effect
        setTimeout(() => {
          window.location.href = data.link; // Redirect after 1 second
        }, 1000); // Adjust the delay as needed
      });
    });

    // Set a minimum time for the preloader (e.g., 3 seconds)
    const minPreloaderTime = 1000;
    const preloaderStartTime = new Date().getTime();

    // Create Preloader
    panorama.addEventListener('load', () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - preloaderStartTime;
      const delayToShow = Math.max(minPreloaderTime - elapsedTime, 0);
    
      setTimeout(() => {
        this.setState({ showPreloader: false, preloaderFinished: true, fadeIn: true }); // Update this line
      }, delayToShow);
    });
    
  }

  render() {
    const { showPreloader, preloaderFinished, fadeIn } = this.state;
    

    return (
      <div>
        {/* Preloader */}
        {showPreloader && (
          <div className="preloader">
            <div className="preloader-inner">
              <video
                autoPlay
                controls={false}
                muted
                playsInline
                className="intro-videop"
                preload="auto"
                loop
              >
                <source src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/loader.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        <div className={`threed-cover ${preloaderFinished && fadeIn ? 'fade-in' : ''}`}>
          <div
            className="image-container"
            ref={(container) => {
              this.imageContainer = container;
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Pool;
