import React, { Component } from 'react';
import * as PANOLENS from 'panolens';


class Bedroom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreloader: true,
      preloaderFinished: false,
      fadeIn: false
    };
  }

  componentDidMount() {

    // Create a panorama
    const panorama = new PANOLENS.ImagePanorama('https://ik.imagekit.io/kassenzio/ms/tr:f-auto/Bedroom4k.jpg');
    const viewer = new PANOLENS.Viewer({
      container: this.imageContainer,
      autoRotate: false,
      controlBar: false,
      autoHideInfospot: false,
      cameraFov: 80,
      control: {
        rotateSpeed: 0.1,
      },
    });

    viewer.container.addEventListener('wheel', function (event) {
      event.preventDefault();
    });

    viewer.add(panorama);

    const control = viewer.getControl();
    control.addEventListener('change', () => {
      if (viewer.camera.rotation.x < -Math.PI / 8) {
        viewer.camera.rotation.x = -Math.PI / 8;
      } else if (viewer.camera.rotation.x > Math.PI / 8) {
        viewer.camera.rotation.x = Math.PI / 8;
      }
    });

    // Create Hotspots

    const hotspots = [
      {
        position: { x: -330, y: 120, z: 750 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/FRONT%20ROOM.png', // Livingroom
        link: '/livingroom', // Livingroom URL
      },
      {
        position: { x: 15, y: -150, z: 800 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/fragrances.png', // Fragrances
        link: 'https://mahasaghar.com/shop/product-category/fragrances/', // Fragrances URL
      },
      {
        position: { x: 500, y: 0, z: 600 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/apparels.png', // APPARELS
        link: 'https://mahasaghar.com/shop/product-category/apparels/', // APPARELS URL
      },
      {
        position: { x: 400, y: 300, z: 700 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/handbags.png', // Handbags
        link: 'https://mahasaghar.com/shop/product-category/handbags/', // Handbags URL
      },
      {
        position: { x: -650, y: -200, z: 200 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/paper%20goods.png', // PaperGoods
        link: 'https://mahasaghar.com/shop/product-category/paper-goods/', // PaperGoods URL
      },
      {
        position: { x: -450, y: 0, z: -550 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/bathroom.png', // Bathroom
        link: '/bathroom', // Bathroom URL
      },
      {
        position: { x: 1000, y: 0, z: -50 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/POOL%20PATIO.png', // Pool
        link: '/pool', // Pool URL
      },
      {
        // Skewed
        position: { x: 600, y: -400, z: 100 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/tableware.png', // Tableware
        link: 'https://mahasaghar.com/shop/product-category/tableware/', // Tableware URL
      },
      {
        // Invisible
        position: { x: 380, y: -250, z: -600 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/bedding.png', // Bedding
        link: 'https://mahasaghar.com/shop/product-category/bedding/', // Bedding URL
      },
      {
        position: { x: 150, y: 50, z: -1000 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/decor.png', // Decor
        link: 'https://mahasaghar.com/shop/product-category/decor/', // Decor URL
      },
    ];

    hotspots.forEach((data, index) => {
      const customHotspot = new PANOLENS.Infospot(100, data.image);
      customHotspot.position.set(data.position.x, data.position.y, data.position.z);
      panorama.add(customHotspot);
    
      // Handle hotspot click
      customHotspot.addEventListener('click', () => {
        this.setState({ fadeIn: false }); // Trigger the fade-in effect
        setTimeout(() => {
          window.location.href = data.link; // Redirect after 1 second
        }, 1000); // Adjust the delay as needed
      });
    });

    // Set a minimum time for the preloader (e.g., 3 seconds)
    const minPreloaderTime = 1000;
    const preloaderStartTime = new Date().getTime();

    // Create Preloader
    panorama.addEventListener('load', () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - preloaderStartTime;
      const delayToShow = Math.max(minPreloaderTime - elapsedTime, 0);
    
      setTimeout(() => {
        this.setState({ showPreloader: false, preloaderFinished: true, fadeIn: true }); // Update this line
      }, delayToShow);
    });
    
  }

  render() {
    const { showPreloader, preloaderFinished, fadeIn } = this.state;
    

    return (
      <div>
        {/* Preloader */}
        {showPreloader && (
          <div className="preloader">
            <div className="preloader-inner">
              <video
                autoPlay
                controls={false}
                muted
                playsInline
                className="intro-videop"
                preload="auto"
                loop
              >
                <source src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/loader.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        <div className={`threed-cover ${preloaderFinished && fadeIn ? 'fade-in' : ''}`}>
          <div
            className="image-container"
            ref={(container) => {
              this.imageContainer = container;
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Bedroom;
