import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const [videoVisible, setVideoVisible] = useState(false);
  const navigate = useNavigate();

  const handleImageClick = () => {
    // Show the video when the image is clicked
    setVideoVisible(true);
  };

  const handleVideoEnd = () => {
    // Redirect to /room when the video ends
    navigate('livingroom');
  };

  return (
    <div className='home'>
      <main>
        {videoVisible ? (
          <video
            autoPlay
            controls={false}
            muted
            playsInline
            className='intro-video'
            onEnded={handleVideoEnd}
            style={{ width: '100%' }}
            preload="auto"
            poster="https://ik.imagekit.io/kassenzio/ms/tr:f-auto/enter-home-1.png" // Add a poster image
          >
            <source src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/Website_3.mp4" type="video/mp4" />
            {/* Add more video sources if needed */}
          </video>
        ) : (
          <div className='home-div'>
            <img
            className='intro-image'
            src="https://ik.imagekit.io/kassenzio/ms/tr:f-auto/enter-home-1.png"
            alt="Intro"
            />
            {/* <img
            className='intro-enter'
            src="https://ik.imagekit.io/kassenzio/ms/tr:f-auto/Enter-HS.png"
            alt="Enter Mahasaghar"
            onClick={handleImageClick}
            style={{ cursor: 'pointer' }}
            /> */}
            <button onClick={handleImageClick} className='enter-btn'> Enter </button>
          </div>
        )}
      </main>
    </div>
  );
}
