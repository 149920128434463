import React, { Component } from 'react';
import * as PANOLENS from 'panolens';


class Bathroom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreloader: true,
      preloaderFinished: false,
      fadeIn: false
    };
  }

  componentDidMount() {

    // Create a panorama
    const panorama = new PANOLENS.ImagePanorama('https://ik.imagekit.io/kassenzio/ms/tr:f-auto/Bathroom4K.jpg');
    const viewer = new PANOLENS.Viewer({
      container: this.imageContainer,
      autoRotate: false,
      controlBar: false,
      autoHideInfospot: false,
      cameraFov: 80,
      control: {
        rotateSpeed: 0.1,
      },
    });

    viewer.container.addEventListener('wheel', function (event) {
      event.preventDefault();
    });

    viewer.add(panorama);

    const control = viewer.getControl();
    control.addEventListener('change', () => {
      if (viewer.camera.rotation.x < -Math.PI / 8) {
        viewer.camera.rotation.x = -Math.PI / 8;
      } else if (viewer.camera.rotation.x > Math.PI / 8) {
        viewer.camera.rotation.x = Math.PI / 8;
      }
    });

    // Create Hotspots

    const hotspots = [
      {
        position: { x: -650, y: -50, z: -550 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/bedroom.png', // Bedroom
        link: '/bedroom', // Bedroom URL
      },
      {
        position: { x: -500, y: 100, z: 250 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/aromatherapy.png', // Aromatherapy
        link: 'https://mahasaghar.com/shop/product-category/aromatherapy/', // Aromatherapy URL
      },
      {
        position: { x: -100, y: 50, z: 750 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/fragrances.png', // Fragrances
        link: 'https://mahasaghar.com/shop/product-category/fragrances/', // Fragrances URL
      },
      {
        position: { x: 350, y: -100, z: 500 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/personal%20care.png', // PersonalCare
        link: 'https://mahasaghar.com/shop/product-category/personal-care/', // PersonalCare URL
      },
      {
        position: { x: 100, y: -400, z: -700 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/footwear.png', // Footwear
        link: 'https://mahasaghar.com/shop/product-category/footwear/', // Footwear URL
      },
      {
        position: { x: 300, y: -100, z: -650 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/jewellery.png', // Jewellery
        link: 'https://mahasaghar.com/shop/product-category/jewellery/', // Jewellery URL
      },
      {
        position: { x: 700, y: 0, z: 75 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/oral%20care.png', // OralCare
        link: 'https://mahasaghar.com/shop/product-category/oral-care/', // OralCare URL
      },
    ];

    hotspots.forEach((data, index) => {
      const customHotspot = new PANOLENS.Infospot(100, data.image);
      customHotspot.position.set(data.position.x, data.position.y, data.position.z);
      panorama.add(customHotspot);
    
      // Handle hotspot click
      customHotspot.addEventListener('click', () => {
        this.setState({ fadeIn: false }); // Trigger the fade-in effect
        setTimeout(() => {
          window.location.href = data.link; // Redirect after 1 second
        }, 1000); // Adjust the delay as needed
      });
    });

    // Set a minimum time for the preloader (e.g., 3 seconds)
    const minPreloaderTime = 1000;
    const preloaderStartTime = new Date().getTime();

    // Create Preloader
    panorama.addEventListener('load', () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - preloaderStartTime;
      const delayToShow = Math.max(minPreloaderTime - elapsedTime, 0);
    
      setTimeout(() => {
        this.setState({ showPreloader: false, preloaderFinished: true, fadeIn: true }); // Update this line
      }, delayToShow);
    });
    
  }

  render() {
    const { showPreloader, preloaderFinished, fadeIn } = this.state;
    

    return (
      <div>
        {/* Preloader */}
        {showPreloader && (
          <div className="preloader">
            <div className="preloader-inner">
              <video
                autoPlay
                controls={false}
                muted
                playsInline
                className="intro-videop"
                preload="auto"
                loop
              >
                <source src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/loader.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        <div className={`threed-cover ${preloaderFinished && fadeIn ? 'fade-in' : ''}`}>
          <div
            className="image-container"
            ref={(container) => {
              this.imageContainer = container;
            }}
          ></div>
        </div>
      </div>
    );
  }
}


export default Bathroom;
