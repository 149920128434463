import React, { Component } from 'react';
import * as PANOLENS from 'panolens';


class Livingroom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreloader: true,
      preloaderFinished: false,
      fadeIn: false
    };
  }

  componentDidMount() {

    // Create a panorama
    const panorama = new PANOLENS.ImagePanorama('https://ik.imagekit.io/kassenzio/ms/tr:f-auto/Livingroom4K.jpg');
    const viewer = new PANOLENS.Viewer({
      container: this.imageContainer,
      autoRotate: false,
      controlBar: false,
      autoHideInfospot: false,
      cameraFov: 80,
      control: {
        rotateSpeed: 0.1,
      },
    });

    viewer.container.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
    panorama.rotation.y = Math.PI / -4;

    viewer.add(panorama);

    const control = viewer.getControl();
    control.addEventListener('change', () => {
      if (viewer.camera.rotation.x < -Math.PI / 8) {
        viewer.camera.rotation.x = -Math.PI / 8;
      } else if (viewer.camera.rotation.x > Math.PI / 8) {
        viewer.camera.rotation.x = Math.PI / 8;
      }
    });

    // Create Hotspots

    const hotspots = [
      {
        position: { x: -950, y: -20, z: 600 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/exterior.png', // Exterior
        link: '/', // Home URL
      },
      {
        position: { x: -250, y: -200, z: 800 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/aromatherapy.png', // Aromatherapy
        link: 'https://mahasaghar.com/shop/product-category/aromatherapy/', // Aromatherapy URL
      },
      {
        position: { x: -50, y: 250, z: 950 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/decor.png', // Decor
        link: 'https://mahasaghar.com/shop/product-category/decor/', // Decor URL
      },
      {
        position: { x: 850, y: -400, z: 500 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/beverages.png', // Deverages
        link: 'https://mahasaghar.com/shop/product-category/beverages/', // Deverages URL
      },
      {
        position: { x: 700, y: -300, z: -250 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/new%20in.png', // New In
        link: 'https://mahasaghar.com/shop/product-category/new/', // New In URL
      },
      {
        position: { x: 280, y: 40, z: -750 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/gifting.png', // Gifting
        link: 'https://mahasaghar.com/shop/product-category/gifting/', // Gifting URL
      },
      {
        position: { x: -1000, y: -250, z: -150 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/mindfulness.png', // Mindfulness
        link: 'https://mahasaghar.com/shop/product-category/mindfulness/', // Mindfulness URL
      },
      {
        position: { x: -120, y: 0, z: -1000 },
        image: 'https://ik.imagekit.io/kassenzio/ms/tr:f-auto/bedroom.png', // Friday Dolly
        link: '/bedroom', // Friday Dolly URL
      },
    ];

    hotspots.forEach((data, index) => {
      const customHotspot = new PANOLENS.Infospot(120, data.image);
      customHotspot.position.set(data.position.x, data.position.y, data.position.z);
      panorama.add(customHotspot);
    
      // Handle hotspot click
      customHotspot.addEventListener('click', () => {
        this.setState({ fadeIn: false }); // Trigger the fade-in effect
        setTimeout(() => {
          window.location.href = data.link; // Redirect after 1 second
        }, 1000); // Adjust the delay as needed
      });
    });

    // Set a minimum time for the preloader (e.g., 3 seconds)
    const minPreloaderTime = 1000;
    const preloaderStartTime = new Date().getTime();

    // Create Preloader
    panorama.addEventListener('load', () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - preloaderStartTime;
      const delayToShow = Math.max(minPreloaderTime - elapsedTime, 0);
    
      setTimeout(() => {
        this.setState({ showPreloader: false, preloaderFinished: true, fadeIn: true }); // Update this line
      }, delayToShow);
    });
    
  }

  render() {
    const { showPreloader, preloaderFinished, fadeIn } = this.state;
    

    return (
      <div>
        {/* Preloader */}
        {showPreloader && (
          <div className="preloader">
            <div className="preloader-inner">
              <video
                autoPlay
                controls={false}
                muted
                playsInline
                className="intro-videop"
                preload="auto"
                loop
              >
                <source src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/loader.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        )}
        <div className={`threed-cover ${preloaderFinished && fadeIn ? 'fade-in' : ''}`}>
          <div
            className="image-container"
            ref={(container) => {
              this.imageContainer = container;
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Livingroom;
